import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import i18next from "i18next";

class Header extends Component {

    handleClickScroll(e,moveTo) {

        e.preventDefault();
        let element = document.getElementById(moveTo);
        element.scrollIntoView();

    }

  render() {
    const { t } = this.props;
    return (
        <header id="home">
            <div className="bg"></div>
            <div className="bg bg2"></div>
            <div className="bg bg3"></div>
            <nav id="nav-wrap">
                <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                <a className="mobile-btn" id="close" href="#close" title="Hide navigation">Hide navigation</a>
                <ul id="nav" className="nav"> 
                    <li><a className="smoothscroll" onClick={e => this.handleClickScroll(e,"home")} href="/">{t('header.home')}</a></li>
                    <li><a className="smoothscroll" onClick={e => this.handleClickScroll(e,"resume")} href="/">{t('header.studies')}</a></li>
                    <li><a className="smoothscroll" onClick={e => this.handleClickScroll(e,"portfolio")} href="/">Portfolio</a></li>
                    <li id="flags">
                        <button className="flag" onClick={() => i18next.changeLanguage('ca')}>
                            <img src="images/flags/cat.png" alt="catala" />
                        </button>
                        <button className="flag" onClick={() => i18next.changeLanguage('es')}>
                            <img src="images/flags/esp.png" alt="castellano" />
                        </button>
                        <button className="flag" onClick={() => i18next.changeLanguage('en')}>
                            <img src="images/flags/eng.png" alt="english" />
                        </button>
                    </li>
                </ul> {/* end #nav */}
            </nav> {/* end #nav-wrap */}
            <div className="row banner">
                <div className="banner-text">
                    <p className="pretitle">{t('header.presentation')}</p>
                    <h1>Marc Cerrillo Matas</h1>
                    <h2>Full Stack Developer</h2>
                    <p>{t('header.content')}</p>
                    <a className="smoothscroll button-52" onClick={e => this.handleClickScroll(e,"portfolio")} href="#">{t('header.button')}</a>
                </div>
            </div>
        </header>

    )
  }
}
export default withTranslation('common')(Header)