import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_ca from "./translations/ca/common.json";
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'es',                              // language to use
  resources: {
      ca: {
          common: common_ca               // 'common' is our custom namespace
      },
      es: {
          common: common_es
      },
      en: {
          common: common_en
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);


