import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

class Footer extends Component {

  handleClickScroll(e,moveTo) {

    e.preventDefault();
    let element = document.getElementById(moveTo);
    element.scrollIntoView();

}

  render() {
    const { t } = this.props;
    return (
        <footer>
  <div className="row">
    <div className="twelve columns">
      <ul className="social-links">
        <li><a href="https://www.linkedin.com/in/marccerrillomatas/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" /></a></li>
        <li><a href="https://github.com/mcmatas" target="_blank" rel="noopener noreferrer"><i className="fa fa-github" /></a></li>
      </ul>
    </div>
    <p style={{color:"darkgrey"}}>{t('footer.contact')} marccerrillomatas@gmail.com</p>
    <div id="go-top"><a className="smoothscroll" title="Back to Top" onClick={e => this.handleClickScroll(e,"home")} href="/"><i className="icon-up-open" /></a></div>
  </div>
</footer>

    )
  }
}
export default withTranslation('common')(Footer)