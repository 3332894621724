import React, { Component } from 'react';
import Header from './components/Header';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio/Portfolio';
import Footer from './components/Footer';

class App extends Component {
  
  render() {
    return (
      <div className="App">
        <Header />
        <Resume />
        <Portfolio />
        <Footer /> 
      </div>
    );
  }
}
export default App;
