import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

class Portfolio extends Component {
  render() {
    const { t } = this.props;
    return (
        <section id="portfolio">
  <div className="row">
    <div className="twelve columns collapsed">
      <h1>{t('portfolio.proj')}</h1>
      {/* portfolio-wrapper */}
      <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">

      <div className="columns portfolio-item">
          <div className="item-wrap">
            <a href="#modal-04">
              <img alt="todolist" src="images/portfolio/dashboard.jpg" />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>AdminPro</h5>
                  <p>WebApp</p>
                </div>
              </div>
              <div className="link-icon"><i className="icon-plus" /></div>
            </a>
          </div>
        </div> {/* item end */}


        <div className="columns portfolio-item">
          <div className="item-wrap">
            <a href="#modal-03">
              <img alt="todolist" src="images/portfolio/todolist.png" />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>TodoList</h5>
                  <p>WebApp</p>
                </div>
              </div>
              <div className="link-icon"><i className="icon-plus" /></div>
            </a>
          </div>
        </div> {/* item end */}

        <div className="columns portfolio-item">
          <div className="item-wrap">
            <a href="#modal-01">
              <img alt="snake" src="images/portfolio/snake.webp" />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>Snake</h5>
                  <p>App</p>
                </div>
              </div>
              <div className="link-icon"><i className="icon-plus" /></div>
            </a>
          </div>
        </div> {/* item end */}

        <div className="columns portfolio-item">
          <div className="item-wrap">
            <a href="#modal-02">
              <img alt="conFusion" src="images/portfolio/confusion.jpg" />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>ConFusion</h5>
                  <p>Web</p>
                </div>
              </div>
              <div className="link-icon"><i className="icon-plus" /></div>
            </a>
          </div>
        </div> {/* item end */}

        
      </div> {/* portfolio-wrapper end */}
    </div> {/* twelve columns end */}

    {/* Modal Popup
	      --------------------------------------------------------------- */}
    <div id="modal-04" className="popup-modal mfp-hide">
      <img className="scale-with-grid" src="images/portfolio/modals/m-dashboard.png" alt="dashboard" />
      <div className="description-box">
        <h4>AdminPro</h4>
           <p>{t('portfolio.adminproDesc')}</p>
           <span className="categories"><i className="fa fa-tag" />TypeScript, Angular, NodeJS, Express, MongoDB, Netlify, Render</span>
      </div>
      <div className="link-box">
        <a href="https://adminpro.marccerrilloweb.com/login"target="_blank" rel="noopener noreferrer" >{t('portfolio.go')}</a>
        <a href=" " className="popup-modal-dismiss">{t('portfolio.close')}</a>
      </div>
    </div>{/* modal-04 End */}

    <div id="modal-03" className="popup-modal mfp-hide">
      <img className="scale-with-grid" src="images/portfolio/modals/m-todoapp.png" alt="todoapp" />
      <div className="description-box">
        <h4>TodoList</h4>
           <p>{t('portfolio.todoDesc')}</p>
           <span className="categories"><i className="fa fa-tag" />HTML, SASS, TypeScript, Angular, BEM</span>
      </div>
      <div className="link-box">
        <a href="https://todolist.marccerrilloweb.com/"target="_blank" rel="noopener noreferrer" >{t('portfolio.go')}</a>
        <a href=" " className="popup-modal-dismiss">{t('portfolio.close')}</a>
      </div>
    </div>{/* modal-03 End */}

    <div id="modal-01" className="popup-modal mfp-hide">
      <img className="scale-with-grid" src="images/portfolio/modals/m-snake.webp" alt="snake" />
      <div className="description-box">
        <h4>Snake</h4>
           <p>{t('portfolio.snakeDesc')}</p>
           <span className="categories"><i className="fa fa-tag" />HTML & CSS, JavaScript, POO</span>
      </div>
      <div className="link-box">
        <a href="https://snake.marccerrilloweb.com/"target="_blank" rel="noopener noreferrer" >{t('portfolio.go')}</a>
        <a href=" " className="popup-modal-dismiss">{t('portfolio.close')}</a>
      </div>
    </div>{/* modal-01 End */}

    <div id="modal-02" className="popup-modal mfp-hide">
      <img className="scale-with-grid" src="images/portfolio/modals/m-confusion.webp" alt="conFusion" />
      <div className="description-box">
        <h4>ConFusion</h4>
         <p>{t('portfolio.confusionDesc')} <i>Front-End JavaScript Frameworks: Angular</i>.</p>
         <span className="categories"><i className="fa fa-tag" />Angular</span>
      </div>
      <div className="link-box">
        <a href="https://confusion.marccerrilloweb.com/" target="_blank" rel="noopener noreferrer">{t('portfolio.go')}</a>
        <a href=" " className="popup-modal-dismiss">{t('portfolio.close')}</a>
      </div>
    </div>{/* modal-02 End */}

    
  </div> {/* row End */}
</section>

    )
  }
}
export default withTranslation('common')(Portfolio)